<template>
  <div id="dustDailyRecord">
    <el-dialog
      :title="dustDailyRecordFormTitle"
      width="1400px"
      :visible.sync="dustDailyRecordDialogVisible"
      :close-on-click-modal="false"
      @close="dustDailyRecordDialogClose"
    >
      <div id="pdfDom">
      <el-form
        ref="dustDailyRecordFormRef"
        :model="dustDailyRecordForm"
        :rules="dustDailyRecordFormRules"
        label-position="left"
        label-width="120px"
      >
        <section class="newRule" style="margin-bottom: 20px">
          <vxe-table
            border
            resizable
            show-overflow
            :span-method="rowspanMethod"
            :data="tableData"
          >
            <vxe-table-column field="monitorPoint" title="序号" align="center">
              <template #default="{ row, rowIndex }">
                <span>{{ tableIndex(row.idx) }}</span>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="monitorPoint"
              title="监测点"
              align="center"
            >
            </vxe-table-column>
            <vxe-table-column
              field="samplePoint"
              title="采样点"
              align="center"
            ></vxe-table-column>
            <vxe-table-colgroup title="尘埃粒子数 个/8.5L" align="center">
              <vxe-table-column
                field="checkRequire"
                title="≥0.5um尘埃粒子（个/8.5L）"
              >
                <template #default="{ row, rowIndex }">
                  <el-input
                    :ref="rowIndex + 'quantity1'"
                    @input="getChnage(row, 'quantity1', rowIndex)"
                    v-model="row.quantity1"
                    placeholder="请输入尘埃粒子数"
                    :readonly="handleType == 'detail' || handleType == 'review'"
                  ></el-input>
                </template>
              </vxe-table-column>
              <vxe-table-column field="avage" title="平均" align="center">
                <template #default="{ row, rowIndex }">
                  <el-input
                    :ref="rowIndex + 'meanValue1'"
                    @input="getChnage(row, 'meanValue1', rowIndex)"
                    v-model="row.meanValue1"
                    placeholder="请输入平均值"
                    :readonly="handleType == 'detail' || handleType == 'review'"
                  ></el-input>
                </template>
              </vxe-table-column>
              <vxe-table-column field="monitorPoint" title="UCL" align="center">
                <template #default="{ row, rowIndex }">
                  <el-input
                    :ref="rowIndex + 'ucl1'"
                    @input="getChnage(row, 'ucl1', rowIndex)"
                    v-model="row.ucl1"
                    placeholder="请输入UCL"
                    :readonly="handleType == 'detail' || handleType == 'review'"
                  ></el-input>
                </template>
              </vxe-table-column>
              <vxe-table-column
                field="checkRequire"
                title="≥5um尘埃粒子（个/8.5L）"
              >
                <template #default="{ row, rowIndex }">
                  <el-input
                    :ref="rowIndex + 'quantity2'"
                    @input="getChnage(row, 'quantity2', rowIndex)"
                    v-model="row.quantity2"
                    placeholder="请输入"
                    :readonly="handleType == 'detail' || handleType == 'review'"
                  ></el-input> </template
              ></vxe-table-column>
              <vxe-table-column field="avage2" title="平均" align="center">
                <template #default="{ row, rowIndex }">
                  <el-input
                    :ref="rowIndex + 'meanValue2'"
                    @input="getChnage(row, 'meanValue2', rowIndex)"
                    v-model="row.meanValue2"
                    placeholder="请输入尘埃粒子数"
                    :readonly="handleType == 'detail' || handleType == 'review'"
                  ></el-input>
                </template>
              </vxe-table-column>
              <vxe-table-column field="monitorPoint" title="UCL" align="center">
                <template #default="{ row, rowIndex }">
                  <el-input
                    :ref="rowIndex + 'ucl2'"
                    @input="getChnage(row, 'ucl2', rowIndex)"
                    v-model="row.ucl2"
                    placeholder="请输入UCL"
                    :readonly="handleType == 'detail' || handleType == 'review'"
                  ></el-input>
                </template>
              </vxe-table-column>
            </vxe-table-colgroup>
            <vxe-table-column
              field="monitorPoint"
              title="检查情况"
              align="center"
            >
              <template #default="{ row, rowIndex }">
                <el-input
                  v-model="row.inspectionSituation"
                  :ref="rowIndex + 'inspectionSituation'"
                  placeholder=""
                  style="position: absolute; opacity: 0; z-index: -1"
                  readonly
                ></el-input>
                <el-radio-group
                  v-model="row.inspectionSituation"
                  @change="getChnage(row, 'inspectionSituation', rowIndex)"
                  :disabled="handleType == 'detail' || handleType == 'review'"
                >
                  <p><el-radio label="1"> 合格 </el-radio></p>
                  <p><el-radio label="2"> 不合格 </el-radio></p>
                </el-radio-group>
              </template>
            </vxe-table-column>
          </vxe-table>
        </section>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="dustDailyRecordForm.remarks"
                :readonly="handleType == 'detail' || handleType == 'review'"
                placeholder="请输入备注"
                clearable
                type="textarea"
                :rows="2"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="handleType !== 'add'">
          <el-col :span="12">
            <el-form-item label="检测人" prop="checker">
              <el-input
                v-model="dustDailyRecordForm.checker"
                placeholder="请输入检测人"
                clearable
                readonly
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检测时间" prop="checkerDate">
              <el-date-picker
                v-model="dustDailyRecordForm.checkerDate"
                placeholder="请选择日期"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                readonly
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          class="newRule"
          v-if="handleType !== 'add' && handleType !== 'update'"
        >
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewerResult">
              <el-radio-group
                v-model="dustDailyRecordForm.reviewerResult"
                :disabled="handleType !== 'review'"
              >
                <el-radio :label="1"> 通过 </el-radio>
                <el-radio :label="2"> 不通过 </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :span="12"
            v-if="handleType !== 'add' && handleType !== 'update'"
          >
            <el-form-item label="复核人" prop="reviewer">
              <el-input
                v-model="dustDailyRecordForm.reviewer"
                placeholder=""
                clearable
                readonly
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="handleType !== 'add' && handleType !== 'update'"
          >
            <el-form-item label="复核日期" prop="reviewerDate">
              <el-date-picker
                v-model="dustDailyRecordForm.reviewerDate"
                placeholder="请选择日期"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                readonly
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      </div>
      <div slot="footer">
        <el-button @click="dustDailyRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="dustDailyRecordFormSubmit">
          确 定
        </el-button>
        <el-button v-if="dustDailyRecordFormTitle === '尘埃粒子检测记录详情'" type="primary" @click="getPdf">
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="检测人">
        <el-input
          v-model="searchForm.checker"
          placeholder="请输入检测人"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dustDailyRecordPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="checker" label="检测人" />
      <el-table-column prop="checkerDate" label="检测日期" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column prop="reviewerDate" label="复核日期" />
      <el-table-column prop="reviewerResult" label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult == 1">通过</span>
          <span v-if="scope.row.reviewerResult == 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="dustDailyRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDustDailyRecord,
  deleteDustDailyRecord,
  updateDustDailyRecord,
  reviewDustDailyRecord,
  selectDustDailyRecordInfo,
  selectDustDailyRecordList,
} from "@/api/quality/dustDailyRecord";
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data() {
    return {
      dustDailyRecordDialogVisible: false,
      dustDailyRecordFormTitle: "",
      dustDailyRecordForm: {
        detailJson: "",
        remarks: "",
      },
      dustDailyRecordFormRules: {
        checkDate: [
          {
            required: true,
            message: "检测时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      dustDailyRecordPage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        // checker: "",
      },
      tableDataDemo: {
        idx: "00",
        inspectionSituation: "",
        meanValue1: "",
        meanValue2: "",
        monitorPoint: "",
        quantity1: "",
        quantity2: "",
        samplePoint: "",
        ucl1: "",
        ucl2: "",
      },
      tableData: [],
      handleType: "",
      typeList: [
        "男二更",
        "女二更",
        "洗衣间",
        "缓冲间",
        // "洗衣间",
        "器具清洗",
        "清洗间",
        "暂存间",
        "组装车间",
        "焊接间",
        "微生物室",
        "无菌室",
        "阳性室",
        "阳性间二更",
        "阳性间缓冲间",
        "二更",
        "无菌室缓冲间",
        "微生物限度室缓冲间",
      ],
    };
  },
  created() {
    selectDustDailyRecordList(this.searchForm).then((res) => {
      this.dustDailyRecordPage = res;
    });
  },
  computed: {
    tableIndex() {
      return function (num) {
        let nums = Number(num);
        return nums + 1;
      };
    },
  },
  methods: {
    dustDailyRecordDialogClose() {
      this.$refs.dustDailyRecordFormRef.resetFields();
      this.dustDailyRecordForm.id = "";
    },
    dustDailyRecordFormSubmit() {
      if (this.dustDailyRecordFormTitle === "尘埃粒子检测记录") {
        this.dustDailyRecordDialogVisible = false;
        return;
      }
      this.$refs.dustDailyRecordFormRef.validate(async (valid) => {
        if (valid) {
          let tempArr = this.tableData;
          if (!tempArr.length) {
            this.$message({
              type: "warning",
              message: "必须有监控记录数据",
            });
            console.log(tempArr, "tempArr");
            return;
          }
          for (let i in tempArr) {
            let commitArr = [
              "quantity1",
              "quantity2",
              "meanValue1",
              "meanValue2",
              "ucl1",
              "ucl2",
              "inspectionSituation",
            ];
            for (let j in commitArr) {
              if (!tempArr[i][commitArr[j]]) {
                let tempInput = i + commitArr[j];
                this.$message({
                  type: "warning",
                  message: `第${this.tableIndex(
                    tempArr[i].idx
                  )}行有数据为空，检测数据输入项不能为空`,
                });
                this.$refs[tempInput].focus();
                return;
              }
            }
          }
          this.dustDailyRecordForm.detailJson = JSON.stringify(tempArr);
          if (this.handleType === "add") {
            await addDustDailyRecord(this.dustDailyRecordForm);
          } else if (this.handleType === "update") {
            await updateDustDailyRecord(this.dustDailyRecordForm);
          } else if (this.handleType === "review") {
            await reviewDustDailyRecord(this.dustDailyRecordForm);
          }
          this.dustDailyRecordPage = await selectDustDailyRecordList(
            this.searchForm
          );
          this.dustDailyRecordDialogVisible = false;
        }
      });
    },
    handleAdd() {
      this.dustDailyRecordFormTitle = "新增尘埃粒子检测记录";
      this.dustDailyRecordDialogVisible = true;
      this.handleType = "add";
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteDustDailyRecord(row.id);
        if (
          this.dustDailyRecordPage.list.length === 1 &&
          this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--;
        }
        this.dustDailyRecordPage = await selectDustDailyRecordList(
          this.searchForm
        );
      });
    },
    handleUpdate(index, row) {
      this.handleType = "update";
      this.dustDailyRecordFormTitle = "修改尘埃粒子检测记录";
      this.dustDailyRecordDialogVisible = true;
      this.selectDustDailyRecordById(row.id);
    },
    handleReview(index, row) {
      this.handleType = "review";
      this.dustDailyRecordFormTitle = "复核尘埃粒子检测记录";
      this.dustDailyRecordDialogVisible = true;
      this.selectDustDailyRecordById(row.id);
    },
    handleInfo(index, row) {
      this.handleType = "detail";
      this.dustDailyRecordFormTitle = "尘埃粒子检测记录详情";
      this.dustDailyRecordDialogVisible = true;
      this.selectDustDailyRecordById(row.id);
    },
    selectDustDailyRecordById(id) {
      selectDustDailyRecordInfo(id).then((res) => {
        console.log(res, "res");
        this.dustDailyRecordForm = res;
        this.tableData = res.detailList.map((item, index) => {
          let length = parseInt(index / 6);
          item.idx = "0" + length;
          if ((index + 3) % 3 === 0) {
            item.jielun = 1;
          } else {
            item.jielun = 2;
          }
          return item;
        });
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      selectDustDailyRecordList(this.searchForm).then((res) => {
        this.dustDailyRecordPage = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      selectDustDailyRecordList(this.searchForm).then((res) => {
        this.dustDailyRecordPage = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      selectDustDailyRecordList(this.searchForm).then((res) => {
        this.dustDailyRecordPage = res;
      });
    },
    // 通用行合并函数（将相同多列数据合并为一行）
    rowspanMethod({ row, _rowIndex, column, visibleData }) {
      let fields = [
        "idx",
        "checkItem",
        "checkRequire",
        "result",
        "jielun",
        "monitorPoint",
        "samplePoint",
        "avage",
        "avage2",
      ];
      let cellValue = row[column.property];
      if (cellValue && fields.includes(column.property)) {
        let prevRow = visibleData[_rowIndex - 1];
        let nextRow = visibleData[_rowIndex + 1];
        if (prevRow && prevRow[column.property] === cellValue) {
          return { rowspan: 0, colspan: 0 };
        } else {
          let countRowspan = 1;
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex];
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 };
          }
        }
      }
    },
    getChnage(row, type, rowIndex) {
      this.tableData[rowIndex][type] = row[type];
      if (type === "meanValue1" || type === "meanValue2") {
        this.tableData[rowIndex + 1][type] = row[type];
        this.tableData[rowIndex + 2][type] = row[type];
      }
      if (row.monitorPoint === "组装车间") {
        if (
          type === "ucl1" ||
          type === "ucl2" ||
          type === "inspectionSituation"
        ) {
          this.tableData[rowIndex + 1][type] = row[type];
          this.tableData[rowIndex + 2][type] = row[type];
          this.tableData[rowIndex + 3][type] = row[type];
          this.tableData[rowIndex + 4][type] = row[type];
          this.tableData[rowIndex + 5][type] = row[type];
          this.tableData[rowIndex + 6][type] = row[type];
          this.tableData[rowIndex + 7][type] = row[type];
          this.tableData[rowIndex + 8][type] = row[type];
        }
      } else {
        if (
          type === "ucl1" ||
          type === "ucl2" ||
          type === "inspectionSituation"
        ) {
          this.tableData[rowIndex + 1][type] = row[type];
          this.tableData[rowIndex + 2][type] = row[type];
          this.tableData[rowIndex + 3][type] = row[type];
          this.tableData[rowIndex + 4][type] = row[type];
          this.tableData[rowIndex + 5][type] = row[type];
        }
      }

      // if (type === "conclusion" && (rowIndex + 2) % 3 === 0) {
      //   this.tableData[rowIndex + 1][type] = row[type];
      // }
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `尘埃粒子检测记录${this.dustDailyRecordForm.checkerDate}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  },
  watch: {
    dustDailyRecordDialogVisible(val) {
      if (!val) {
        this.dustDailyRecordForm = {};
        this.handleType = "";
        this.tableData = [];
      } else {
        if (this.handleType === "add") {
          let tableData = [];
          let tableDataDemo = JSON.parse(JSON.stringify(this.tableDataDemo));
          let typeList = this.typeList;
          let tempArr = [];
          for (let i in typeList) {
            if (typeList[i] === "组装车间") {
              let tempArr2 = [
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
              ];
              for (let j in tempArr2) {
                tempArr2[j].monitorPoint = typeList[i];
                // tempArr2[j].meanValue1 = 1;
                // tempArr2[j].meanValue2 = 1;
                // tempArr2[j].quantity1 = 1;
                // tempArr2[j].quantity2 = 1;
                // tempArr2[j].ucl1 = 1;
                // tempArr2[j].ucl2 = 1;
                // tempArr2[j].inspectionSituation = 1;
                if (j < 3) {
                  tempArr2[j].samplePoint = 1;
                  tempArr2[j].avage = "1";
                  tempArr2[j].avage2 = "1";
                } else if (j < 6) {
                  tempArr2[j].samplePoint = 2;
                  tempArr2[j].avage = "2";
                  tempArr2[j].avage2 = "2";
                } else {
                  tempArr2[j].samplePoint = 3;
                  tempArr2[j].avage = "3";
                  tempArr2[j].avage2 = "3";
                }
              }
              tempArr.push(...tempArr2);
            } else {
              let tempArr2 = [
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
                JSON.parse(JSON.stringify(tableDataDemo)),
              ];
              for (let j in tempArr2) {
                tempArr2[j].monitorPoint = typeList[i];
                // tempArr2[j].meanValue1 = 1;
                // tempArr2[j].meanValue2 = 1;
                // tempArr2[j].quantity1 = 1;
                // tempArr2[j].quantity2 = 1;
                // tempArr2[j].ucl1 = 1;
                // tempArr2[j].ucl2 = 1;
                // tempArr2[j].inspectionSituation = 1;
                if (j < 3) {
                  tempArr2[j].samplePoint = 1;
                  tempArr2[j].avage = "1";
                  tempArr2[j].avage2 = "1";
                } else if (j < 6) {
                  tempArr2[j].samplePoint = 2;
                  tempArr2[j].avage = "2";
                  tempArr2[j].avage2 = "2";
                }
              }
              tempArr.push(...tempArr2);
            }
          }
          tableData = tableData.concat(...tempArr);
          tableData = tableData.map((item, index) => {
            let length = parseInt(index / 6);
            item.idx = "0" + length;
            return item;
          });
          this.tableData = tableData;
        }
      }
    },
  },
};
</script>

<style lang='css'>
.newRule {
  /* margin-bottom: 20px; */
}
.newRule .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border-color: #dcdfe6;
  color: #606266;
}
.newRule .el-radio__input.is-disabled.is-checked + span.el-radio__label {
  color: #409eff;
}
.newRule .el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: #409eff;
  background: #409eff;
}
.newRule .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #f5f7fa;
}
.el-form {
  padding-left: 20px;
}
</style>
