import axios from '@/common/axios'
import qs from 'qs'

export function addDustDailyRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/dustRecord/add',
    data: qs.stringify(data)
  })
}

export function deleteDustDailyRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/dustRecord/delete/' + id
  })
}

export function updateDustDailyRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/dustRecord/update',
    data: qs.stringify(data)
  })
}

export function reviewDustDailyRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/dustRecord/review',
    data: qs.stringify(data)
  })
}

export function selectDustDailyRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/dustRecord/info/' + id
  })
}

export function selectDustDailyRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/dustRecord/list',
    params: query
  })
}
